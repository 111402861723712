export interface RiskStatusDTO {
  id: number;
  title_en: string;
  title_ar: string;
  color_code: string;
  type: string;
  status: string;
}
export interface RiskStatusDetailsDTO {
  id: number;
  title: string;
  color_code: string;
  type: string;
  status: string;
}

export function convertResponseToRiskStatusDetailsDTO(
  response: any,
  lang: string
): RiskStatusDetailsDTO {
  return {
    id: response.id,
    title: response[`title_${lang}`],
    status: response.status,
    type: response.type,
    color_code: response.color_code,
  };
}
