export interface RiskSourceDTO {
  id: number;
  title_en: string;
  title_ar: string;
  status: string;
}

export interface RiskSourceDetailsDTO {
  id: number;
  title: string;
  status: string;
}

export interface RiskSourceFormDTO {
  id?: number;
  title_en: string;
  title_ar: string;
  status: string;
}

export interface RiskSourceDetailsDTO {
  id: number;
  title: string;
  status: string;
}

export function convertResponseToRiskSourceDTO(
  response: any,
  lang: string
): RiskSourceDetailsDTO | null {
  if (!response) {
    return null;
  }
  return {
    id: response.id,
    title: response[`title_${lang}`],
    status: response.status,
  };
}

export function convertResponseToRiskSourceDetailsDTO(
  response: any,
  lang: string
): RiskSourceDetailsDTO | null {
  if (!response) {
    return null;
  }
  return {
    id: response.id,
    title: response[`title_${lang}`],
    status: response.status,
  };
}
