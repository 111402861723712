export interface RiskResponseStrategyDTO {
  id: number;
  title_en: string;
  title_ar: string;
  description_en: string;
  description_ar: string;
  status: string;
}

export interface RiskResponseStrategyListDTO {
  id: number;
  title: string;
  description: string;
  status: string;
}

export interface RiskResponseStrategyFormDTO {
  id: number;
  title_en: string;
  title_ar: string;
  description_en: string;
  description_ar: string;
  status: string;
}

export interface RiskResponseStrategyDetailsDTO {
  id: number;
  title: string;
  description: string;
  status: string;
}

export function exportResToRiskResponseStrategyDetailsDTOArray(
  response: any,
  lang: string
): RiskResponseStrategyDetailsDTO[] {
  return response
    .map((item: any) =>
      convertResponseToRiskResponseStrategyDetailsDTO(item, lang)
    )
    .filter((item: null) => item !== null);
}

export function convertResponseToRiskResponseStrategyDetailsDTO(
  response: any,
  lang: string
): RiskResponseStrategyDetailsDTO | null {
  if (!response) {
    return null;
  }
  return {
    id: response.id,
    title: response[`title_${lang}`],
    description: response[`description_${lang}`],
    status: response.status,
  };
}
