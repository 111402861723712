import { FormGroup } from '@angular/forms';
import { MainConversionInterface } from '../../../../core/interfaces/conversion/main-conversion.interface';
import {
  RiskRegisterDetailsDTO,
  RiskRegisterFormDTO,
  RiskRegisterListDTO,
} from '../dto/risk-register.dto';
import { CurrentUserPreferenceStore } from '../../../../core/store/current-user-preference.store';
import { TableConversion } from '../../../../shared/conversion/table.conversion';
import { UserConversion } from '../../../organization/users/conversion/user.conversion';
import { Injectable } from '@angular/core';
import { convertResponseToRiskCategoryDetailsDTO } from '../../../settings/risk-management/dto/risk-category.dto';
import { convertResponseToReviewFrequencyDTO } from '../../../settings/masters/dto/review-frequency.dto';
import { convertResponseToRiskStatusDetailsDTO } from '../../../settings/risk-management/dto/risk-status.dto';
import { convertResponseToRiskSourceDTO } from '../../../settings/risk-management/dto/risk-source.dto';
import { RiskImpactConversion } from '../../../settings/risk-management/conversion/risk-impact.conversion';
import { RiskLikelihoodConversion } from '../../../settings/risk-management/conversion/risk-likelihood.conversion';
import { RiskRatingConversion } from '../../../settings/risk-management/conversion/risk-rating-conversion';
import { convertResponseToFileDetailsDTO } from '../../../../shared/dto/file.dto';
import { BarChartDataDTO } from '../../../../core/modals/chart.modal';
import * as am5 from '@amcharts/amcharts5';
import { convertResponseToRiskResponseStrategyDetailsDTO } from '../../../settings/risk-management/dto/risk-response-strategies.dto';

@Injectable({
  providedIn: 'root',
})
export class RiskRegisterConversion
  implements
    MainConversionInterface<
      RiskRegisterListDTO,
      RiskRegisterFormDTO,
      RiskRegisterDetailsDTO
    >
{
  private lang: string;

  constructor(
    private currentUserPreferenceStore: CurrentUserPreferenceStore,
    private tableConv: TableConversion,
    private userConv: UserConversion,
    private riskImpactConversion: RiskImpactConversion,
    private riskLikelihoodConversion: RiskLikelihoodConversion,
    private riskRatingConversion: RiskRatingConversion
  ) {
    this.lang = this.currentUserPreferenceStore.languageKey;
  }
  resToList(response: any[]): RiskRegisterListDTO[] {
    if (!response) {
      return [];
    }
    const result = response.map((item) => {
      return {
        id: item.id,
        reference_number: item.reference_number,
        risk_category_title: item[`risk_category_title_${this.lang}`],
        risk_source_title: item[`risk_source_title_${this.lang}`],
        responsible_unit_id: item.responsible_unit_id,
        responsible_unit_title: item[`responsible_unit_title_${this.lang}`],
        responsible_user: this.userConv.resToUserMinimalDTO(
          item,
          'responsible_user'
        ),
        responsible_user_first_name:
          item[`responsible_user_first_name_${this.lang}`],
        responsible_user_last_name:
          item[`responsible_user_last_name_${this.lang}`],
        risk_status: this.tableConv.resToColordItem(item, 'risk_status'),
        title: item.title,
        description: item.description,
        reported_at: item.reported_at,
        review_frequency_title: item[`review_frequency_title_${this.lang}`],
        next_review_date: item.next_review_date,
        current_risk_rating_value_id: item.current_risk_rating_value_id,
        created_at: item.created_at,
        updated_at: item.updated_at,
        created_user_first_name: item[`created_user_first_name_${this.lang}`],
        created_user_last_name: item[`created_user_last_name_${this.lang}`],
        updated_user_first_name: item[`updated_user_first_name_${this.lang}`],
        updated_user_last_name: item[`updated_user_last_name_${this.lang}`],
        current_likelihood_title: item[`current_likelihood_title_${this.lang}`],
        current_impact_title: item[`current_impact_title_${this.lang}`],
        current_risk_rating: this.tableConv.resToColordItem(
          item,
          'current_risk_rating'
        ),
        risk_response: item[`risk_response_strategy_title_${this.lang}`],
        current_risk_rating_value: item.current_risk_rating_value,
        inherent_likelihood_title:
          item[`inherent_likelihood_title_${this.lang}`],
        inherent_impact_title: item[`inherent_impact_title_${this.lang}`],
        inherent_risk_rating: this.tableConv.resToColordItem(
          item,
          'inherent_risk_rating'
        ),
        inherent_risk_rating_value: item.inherent_risk_rating_value,
        residual_likelihood_title:
          item[`residual_likelihood_title_${this.lang}`],
        residual_impact_title: item[`residual_impact_title_${this.lang}`],
        residual_risk_rating: this.tableConv.resToColordItem(
          item,
          'residual_risk_rating'
        ),
        residual_risk_rating_value: item.residual_risk_rating_value,
      };
    });
    return result;
  }

  formGroupToForm(formGroup: FormGroup): RiskRegisterFormDTO {
    return {
      id: formGroup.get('id')?.value,
      title: formGroup.get('Title')?.value,
      description: formGroup.get('Description')?.value,
      risk_category_id: formGroup.get('RiskCategory')?.value,
      risk_status_id: parseInt(formGroup.get('RiskStatus')?.value, 10),
      risk_source_id: formGroup.get('RiskSource')?.value,
      owner_unit_id: 1, // TODO Data should be taken from form
      responsible_user_id: formGroup.get('ResponsibleUser')?.value,
      reported_at: formGroup.get('ReportedAt')?.value,
      review_frequency_id: formGroup.get('ReviewFrequency')?.value,
      next_review_date: formGroup.get('NextReviewDate')?.value,
      documents: formGroup.get('Documents')?.value,
      control_ids: formGroup.get('Controls')?.value,
      process_ids: formGroup.get('Processes')?.value,
      compliance_requirement_ids: formGroup.get('CompliantRequirements')?.value,
      strategic_objective_ids: [],
      sla_register_ids: [],
    };
  }
  resToForm(response: any): any {
    return {
      id: response.id,
      Title: response.title,
      Description: response.description,
      RiskCategory: response.risk_category?.id,
      RiskSource: response.risk_source?.id,
      RiskStatus: response.risk_status?.id,
      OwnerUnit: response.owner_unit?.id,
      ResponsibleUser: response.responsible_user?.id,
      ReportedAt: response.reported_at,
      NextReviewDate: response.next_review_date,
      ReviewFrequency: response.review_frequency?.id,
      Documents: response.documents,
      Controls: response.controls.map((control: any) => control.control_id),
      Processes: response.processes.map((process: any) => process.process_id),
      CompliantRequirements: response.compliance_requirements.map(
        (requirement: any) => requirement.compliance_requirement_id
      ),
    };
  }
  resToDetails(response: any): RiskRegisterDetailsDTO {
    return {
      id: response.id,
      reference_number: response.reference_number,
      title: response.title,
      description: response.description,
      reported_at: response.reported_at,
      next_review_date: response.next_review_date,
      created_at: response.created_at,
      updated_at: response.updated_at,
      marked_as_auditable_item: response.marked_as_auditable_item,
      documents: response.documents?.map((doc: any) =>
        convertResponseToFileDetailsDTO(doc)
      ),
      current_risk_rating: this.riskRatingConversion.returnDetailsOrNull(
        response.current_risk_rating
      ),
      current_risk_rating_value: response.current_risk_rating_value,
      inherent_likelihood: this.riskLikelihoodConversion.resToDetails(
        response.inherent_likelihood
      ),
      inherent_impact: this.riskImpactConversion.resToDetails(
        response.inherent_impact
      ),
      current_likelihood: this.riskLikelihoodConversion.resToDetails(
        response.current_likelihood
      ),
      current_impact: this.riskImpactConversion.resToDetails(
        response.current_impact
      ),
      inherent_risk_rating: this.riskRatingConversion.returnDetailsOrNull(
        response.inherent_risk_rating
      ),
      inherent_risk_rating_value: response.inherent_risk_rating_value?.value,
      residual_likelihood: this.riskLikelihoodConversion.resToDetails(
        response.residual_likelihood
      ),
      residual_impact: this.riskImpactConversion.resToDetails(
        response.residual_impact
      ),
      residual_risk_rating: this.riskRatingConversion.returnDetailsOrNull(
        response.residual_risk_rating
      ),
      residual_risk_rating_value: response.residual_risk_rating_value?.value,
      risk_category: convertResponseToRiskCategoryDetailsDTO(
        response.risk_category,
        this.lang
      ),
      risk_response: convertResponseToRiskResponseStrategyDetailsDTO(
        response.risk_response_strategy,
        this.lang
      ),
      review_frequency: response.review_frequency
        ? convertResponseToReviewFrequencyDTO(
            response.review_frequency,
            this.lang
          )
        : null,
      risk_rating: this.riskRatingConversion.returnDetailsOrNull(
        response.risk_rating
      ),
      risk_status: convertResponseToRiskStatusDetailsDTO(
        response.risk_status,
        this.lang
      ),
      created_by: this.userConv.resObjToUserMinimalDTO(response.created_by),
      updated_by: this.userConv.resObjToUserMinimalDTO(response.updated_by),
      responsible_user: this.userConv.resObjToUserMinimalDTO(
        response.responsible_user
      ),
      risk_source: convertResponseToRiskSourceDTO(
        response.risk_source,
        this.lang
      ),
      control_count: response.controls?.length,
    };
  }

  resToBarChart(
    inherentControlScore: number,
    residualControlScore: number
  ): BarChartDataDTO[] {
    return [
      {
        category: 'Inherent Control Score',
        value: inherentControlScore,
        columnSettings: {
          fill: this.getColorForValue(inherentControlScore),
        },
      },
      {
        category: 'Residual Control Score',
        value: residualControlScore,
        columnSettings: {
          fill: this.getColorForValue(residualControlScore),
        },
      },
    ];
  }

  getColorForValue(score: number): am5.Color {
    if (score <= 50) {
      return am5.color('#E0554D');
    } else if (score <= 70) {
      return am5.color('#FFA500');
    } else {
      return am5.color('#28A745');
    }
  }
}
